import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { MdPrint } from 'react-icons/md';
import { useReactToPrint } from 'react-to-print';
import { useDispatch, useSelector } from 'react-redux';
import { entries, keys, omit } from 'lodash';
import styled from 'styled-components';
import { setMonth, setYear } from 'date-fns';
import Spinner from 'react-md-spinner';
import { useFormik } from 'formik';
import Modal, { ModalFooter, ModalHeader } from 'components/v3/Modal';
import { Grid, GridItem } from 'components/v3/ui';
import { UserAction, UserSelector } from 'features/Users/data';
import { useRedux } from 'components/v3/utils';
import { theme } from 'ui';
import { parseDate, printDate } from 'utils/dates';
import { useMonthDates } from './useMonthDates';
import { WorkRecordsWeekTable } from './WorkRecordWeekTable';
import { CardButtons } from 'components/v3/ui/card';
import { SelectInputLayout } from 'components/v3/InputLayout';
import { WorkRecordRecap } from './WorkRecordRecap';
import Button from 'components/v3/ui/button';

const Form = styled.form`
  @media print {
    transform: scale(0.4);
    font-size: 10px !important;
  }
`;

const SmallSelectInputLayout = styled.div`
  input {
    padding: 6px 10px;
  }
`.withComponent(SelectInputLayout);

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 10px;
`;

const UserName = styled.div`
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 10px;
`;

const MONTHS = [...Array(12).keys()].map((monthIndex) => ({
  label: printDate(new Date(0, monthIndex), 'MMMM'),
  value: monthIndex
}));

export const UserWorkRecordsModal = ({ onRequestClose, date: propsDate, ...props }) => {
  const printedElementRef = useRef(null);
  const { userId } = useParams();
  const dispatch = useDispatch();
  const [date, setDate] = useState(parseDate(propsDate || new Date()));
  const [, fetch, state] = useRedux(null, UserAction.fetchWorksRecords, {
    fetchOnMount: false,
    useLocalState: true
  });
  const fetchParams = useMemo(
    () => ({
      userId,
      year: printDate(date, 'yyyy'),
      month: printDate(date, 'MM')
    }),
    [date, userId]
  );

  const selectUser = useMemo(() => (state) => UserSelector.selectUserV3(state, { userId }), [userId]);
  const user = useSelector(selectUser);

  const formik = useFormik({
    initialValues: {},
    onSubmit: (data) => {
      const workRecords = [];
      entries(data)
        .filter(([_, { updated }]) => updated)
        .forEach(([date, dayData]) => {
          const record = { date };
          if (dayData.zone !== undefined) {
            record.zone = dayData.zone;
          }
          entries(dayData).forEach(([key, data]) => {
            if (data?.kind === 'work') {
              record[key] = data.duration ?? null;
            }
          });
          if (keys(omit(record, 'date')).length > 0) {
            workRecords.push(record);
          }
        });
      return dispatch(
        UserAction.updateWorkRecords({
          ...fetchParams,
          user: {
            workTimeRecordsAttributes: workRecords
          }
        })
      ).then(() => onRequestClose());
    }
  });

  const { resetForm } = formik;

  const handlePrint = useReactToPrint({
    content: () => printedElementRef.current
  });

  useEffect(() => {
    fetch(fetchParams);
  }, [userId, date, fetch, fetchParams]);

  const dates = useMonthDates({ date, data: formik.values });

  useEffect(() => {
    if (state.result?.data) {
      resetForm({
        values: state.result.data
      });
    }
  }, [state.result, resetForm]);

  const yearsOptions = useMemo(() => {
    let year = new Date().getFullYear() + 2;
    const years = [];
    while (year >= 2018) {
      years.push(year);
      year--;
    }
    return years.map((year) => ({
      value: year,
      label: year
    }));
  }, []);

  return (
    <Modal isOpen size="normal" onRequestClose={onRequestClose} {...props}>
      <Form onSubmit={formik.handleSubmit} style={{ overflow: 'auto' }}>
        <ModalHeader
          key={userId}
          subtitle={
            <Button onClick={handlePrint} variant="text" icon={MdPrint} small>
              Imprimer
            </Button>
          }
        />
        <Grid spacing={16} padded ref={printedElementRef}>
          <GridItem width="60%">
            <UserName>{user?.displayName}</UserName>
            <FlexDiv>
              <SmallSelectInputLayout
                variant="outline"
                value={date.getFullYear()}
                options={yearsOptions}
                mapOptionValue
                sortOptions={false}
                isClearable={false}
                onChange={(year) => setDate((date) => setYear(date, year))}
              />
              <SmallSelectInputLayout
                variant="outline"
                value={date.getMonth()}
                options={MONTHS}
                mapOptionValue
                sortOptions={false}
                isClearable={false}
                onChange={(month) => setDate((date) => setMonth(date, month))}
              />
              {state.isLoading && (
                <div style={{ position: 'relative' }}>
                  <Spinner size={20} singleColor={theme.primary} style={{ position: 'absolute', top: 16, left: -16 }} />
                </div>
              )}
            </FlexDiv>
          </GridItem>
          <GridItem width="40%">
            <WorkRecordRecap formik={formik} year={date.getFullYear()} month={date.getMonth()} />
          </GridItem>
          {dates.map((date) => {
            return (
              <GridItem width="100%" key={`${date.days[0].dateKey}`}>
                <WorkRecordsWeekTable week={date.week} days={date.days} formik={formik} />
              </GridItem>
            );
          })}
        </Grid>
        <ModalFooter>
          <CardButtons
            positiveDisabled={!formik.isValid}
            buttonNameLoading={formik.isSubmitting ? 'positive' : null}
            positiveText="Enregistrer"
          />
        </ModalFooter>
      </Form>
    </Modal>
  );
};
