// WorkRecordZone.js
import React from 'react';
import { get } from 'lodash';
import { TextInputLayout } from 'components/v3/InputLayout';
import styled from 'styled-components';

const CenteredTextInput = styled(TextInputLayout)`
  & {
    text-align: center !important;
  }
`;


export const WorkRecordZone = ({ dateKey, formik }) => {
  // Access the `zone` value in formik => formik.values[YYYY-MM-DD].zone
  const zoneValue = get(formik.values, [dateKey, 'zone']) || '';

  // Render a table cell with an inline input
  return (
    <td>
      <CenteredTextInput
        variant="flat.small"
        placeholder=""
        value={zoneValue}
        onChange={(newValue) => {
          // Store in formik
          formik.setFieldValue(`${dateKey}.zone`, newValue);
          // Mark the day as 'updated' so you'll pick it up in your final save
          formik.setFieldValue(`${dateKey}.updated`, true);
        }}
      />
    </td>
  );
};
