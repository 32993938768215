import React, { useMemo, useEffect, useState } from 'react';
import styled from 'styled-components';
import AutoSizer from 'react-virtualized-auto-sizer';
import { entries, sortBy } from 'lodash';
import { useAction, useRedux } from 'components/v3/utils';
import { FilterBar, useFilters } from 'components/v3/Filters';
import { Container, Text, Title } from 'components/v3/ui';
import Table, { HeaderWrapper } from 'components/v3/List/Table';
import SubHeader from 'components/v3/SubHeader';
import { toaster } from 'components/v3/Toast';
import { useAccountConfig } from 'config';
import { GroupSelector, GroupAction } from 'features/Groups';
import { StatsApi } from 'features/Stats';
import { printDate } from 'utils/dates';
import { fontSize } from 'ui';

const ListWrapper = styled.div`
  ${HeaderWrapper} {
    width: unset;
    > div:first-child {
      width: unset;
      > div:last-child {
        padding-right: 12px;
      }
    }
  }
`;

const BillingStats = () => {
  const { accountId } = useAccountConfig();
  const action = useAction();
  const [isLoading, setLoading] = useState(false);
  const [apiData, setApiData] = useState({});
  const [groups] = useRedux(GroupSelector.selectGroups, GroupAction.getGroups, {
    fetchOnMount: true,
    useLocalState: true,
    initialParams: {
      page: 1,
      perPage: 500,
      useCache: true
    }
  });

  const MONTHS = useMemo(() => {
    let date = new Date();
    return Array(12)
      .fill({})
      .map((_, idx) => {
        date.setMonth(idx);
        return {
          month: idx + 1,
          label: printDate(date, 'MMM')
        };
      });
  }, []);

  const FILTERS = useMemo(() => {
    const year = new Date().getFullYear();
    return [
      {
        kind: 'select',
        key: 'byYear',
        value: year,
        asQueryParam: value => ({
          byYear: value
        }),
        props: {
          style: {
            minWidth: 100
          },
          isClearable: false,
          fillWidth: true,
          label: 'Année',
          fitCurrentOptionWidth: true,
          options: Array.from(new Array(20), (_, index) => year - index + 2)
            .filter(v => v > 2015)
            .map(value => ({
              value,
              label: value
            })),
          mapOptionValue: true
        }
      }
    ];
  }, []);

  const [filters, setFilters] = useFilters(FILTERS);

  useEffect(() => {
    action(() =>
      StatsApi.getBillingStats({
        ...filters.asQueryParams()
      })
    )
      .onBegin(() => {
        setApiData({});
        setLoading(true);
      })
      .onSuccess(data =>
        setApiData(
          data.reduce((acc, data) => {
            let months = acc[data.groupId];
            let summaryMonths = acc['summary'] || {};
            if (!months) {
              months = {};
            }
            acc[data.groupId] = {
              ...months,
              [data.month || 'order']: data.amount || ''
            };
            acc['summary'] = {
              ...summaryMonths,
              [data.month || 'order']:
                Number(summaryMonths[data.month || 'order'] || 0) +
                Number(data.amount || 0)
            };
            return acc;
          }, {})
        )
      )
      .onError(toaster.error)
      .onEnd(() => setLoading(false));
  }, [action, accountId, filters]);

  const columns = useMemo(() => {
    const isCurrentYear = filters[0]?.value === new Date().getFullYear();
    const cols = [
      {
        Header: ' ',
        accessor: 'displayName',
        width: '240px',
        Cell: ({ item }) => (
          <Text
            variant="small"
            weight={item.id === 'summary' ? 'bold' : 'regular'}
          >
            {item.displayName}
          </Text>
        )
      },
      ...MONTHS.map(({ month, label }) => ({
        Header: label,
        accessor: month,
        minWidth: 80,
        textAlign: 'end',
        Cell: ({ item }) => (
          <Text
            variant="small"
            currency
            decimalScale={0}
            weight={item.id === 'summary' ? 'bold' : 'regular'}
          >
            {item.months[month] || ''}
          </Text>
        )
      }))
    ];
    // Add 'Total Année' column
    cols.push({
      Header: 'Total Année',
      accessor: 'yearTotal', // We'll calculate on the fly
      minWidth: 120,
      textAlign: 'end',
      Cell: ({ item }) => {
        // Sum each month from item.months
        const total = MONTHS.reduce((acc, { month }) => {
          return acc + Number(item.months[month] || 0);
        }, 0);

        return (
          <Text
            variant="small"
            currency
            decimalScale={0}
            style={{ paddingRight: 4 }}
            weight={item.id === 'summary' ? 'bold' : 'regular'}
          >
            {total}
          </Text>
        );
      }
    });
    // Add 'En-cours' column (non-billed orders in the business pipeline)
    if (isCurrentYear) {
      cols.push({
        Header: 'En-cours',
        accessor: 'order',
        minWidth: 120,
        textAlign: 'end',
        Cell: ({ item }) => (
          <Text
            variant="small"
            currency
            decimalScale={0}
            style={{ paddingRight: 4 }}
            weight={item.id === 'summary' ? 'bold' : 'regular'}
          >
            {item.months['order']}
          </Text>
        )
      });
    }
    return cols;
  }, [MONTHS, filters]);

  const { data, billed, pending } = useMemo(() => {
    const data = [
      ...sortBy(groups, 'displayName').map(group => ({
        ...group,
        months: apiData[group.id] || {}
      }))
    ];
    if (apiData['null']?.order) {
      data.push({
        id: 'null',
        displayName: 'Sans Groupe',
        months: apiData['null'] || {}
      });
    }
    data.push({
      id: 'summary',
      displayName: 'Total',
      months: apiData['summary'] || {}
    });
    return {
      data,
      ...entries(apiData['summary']).reduce(
        (acc, [month, amount]) => {
          if (month === 'order') {
            acc.pending = amount;
          } else {
            acc.billed = acc.billed + amount;
          }
          return acc;
        },
        { billed: 0, pending: 0 }
      )
    };
  }, [groups, apiData]);

  return (
    <Container vertical>
      <SubHeader isLoading={isLoading}>
        <Title style={{ minWidth: 150 }}>
          Facturé:
          <Text
            currency
            decimalScale={0}
            weight="bold"
            style={{ paddingLeft: 8, paddingRight: 0, fontSize: fontSize(18) }}
          >
            {billed}
          </Text>
          , En-cours:
          <Text
            variant=""
            currency
            decimalScale={0}
            weight="bold"
            style={{ paddingLeft: 8, paddingRight: 0, fontSize: fontSize(18) }}
          >
            {pending}
          </Text>
        </Title>
        <div>
          <FilterBar
            big
            style={{ margin: 0 }}
            filters={filters}
            onChanges={setFilters}
            disableSearch
          />
        </div>
      </SubHeader>
      <Container vertical padded>
        <AutoSizer>
          {({ height, width }) => (
            <ListWrapper style={{ height, width }}>
              <Table items={data} columns={columns} headerVariant="dark" />
            </ListWrapper>
          )}
        </AutoSizer>
      </Container>
    </Container>
  );
};

export default BillingStats;
